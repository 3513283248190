export const sortOrderValue = {
  asc: 'ascending',
  desc: 'descending'
}

export const sortPropertyValue = {
  created: 'created',
  user: 'user',
  type: 'type'
}

export const initSortValue = {
  sortOrder: sortOrderValue.asc,
  sortProp: sortPropertyValue.created
}
