<template lang="pug">
  .absolute.bottom-5.top-5.left-5.z-30.bg-white.shadow.grid.grid-cols-2.pr-4.pl-6.pt-5.w-module.session-container(
    style='grid-template-rows: auto auto auto 1fr'
    ref='wrapper'
    tabindex="0"
    @keyup.esc="$emit('close')"
  )
    .text-darkblue.col-start-1.col-end-2.text-xl.font-bold.whitespace-no-wrap.font-SourceSansPro {{ $t("sessions.title") }}
    .flex.justify-end.col-start-2
      i.el-icon-close.text-2xl.cursor-pointer.text-darkblue.font-semibold(
        @click='$emit("close")'
      )
    skif-search.col-start-1.col-end-3.mt-4(
      v-model='filterString'
      :placeholder='$t("search")'
      @searching="searching"
    )
    .flex.col-start-1.col-end-3.items-center(style="justify-content: space-between")
      span.col-start-1.col-end-3.flex.mb-3.mt-4.items-center.font-SourceSansPro
        span.mr-8.pr-3.font-semibold.focus_outline-none.cursor-pointer.transition-colors.duration-300(
          @click='changeTab("day")',
          :class='activeTab === "day" ? "text-darkblue" : "text-annotationColor"'
        ) {{ $t("sessions.day") }}
        span.mr-8.pr-3.font-semibold.focus_outline-none.cursor-pointer.transition-colors.duration-300(
          @click='changeTab("week")',
          :class='activeTab === "week" ? "text-darkblue" : "text-annotationColor"'
        ) {{ $t("sessions.week") }}
        span.mr-8.pr-3.font-semibold.focus_outline-none.cursor-pointer.transition-colors.duration-300(
          @click='changeTab("month")',
          :class='activeTab === "month" ? "text-darkblue" : "text-annotationColor"'
        ) {{ $t("sessions.month") }}
      a.skif-load-link(
        :href='sessionsHref',
        @click='downloadSessions',
        target='_blank'
      )
        downloadIcon
    .col-start-1.col-end-3.pr-2.overflow-y-auto
      .table.w-full
        .table-header-group.text-left
          .table-row
            .table-cell.bg-white.top-0.sticky
              .flex.items-center
                span.text-annotationColor.text-sm.font-SourceSansPro.leading-10.font-semibold.mr-2 {{ $t("sessions.time") }}
                svg.transition-transform.ease-in-out.duration-500.cursor-pointer(
                  @click='sortChange(sortPropertyValue.created)',
                  width='24',
                  height='24',
                  fill='none',
                  xmlns='http://www.w3.org/2000/svg',
                  :style='sortProp === sortPropertyValue.created && sortOrder === "descending" && { transform: "scale(1,-1)" }'
                )
                  path.transition-colors.ease-in-out.duration-200(
                    d='M6.395 20a.677.677 0 00.68-.682V5.683a.683.683 0 00-.68-.682.677.677 0 00-.679.682v13.635a.683.683 0 00.68.682z',
                    :fill='sortProp === sortPropertyValue.created ? "#3C5E8F" : "#91B4E7"'
                  )
                  path.transition-colors.ease-in-out.duration-200(
                    d='M9.591 8.893a.682.682 0 000-.966L6.876 5.2a.679.679 0 00-.962 0L3.2 7.927a.683.683 0 00.74 1.114.68.68 0 00.221-.148l2.235-2.246L8.63 8.893a.679.679 0 00.961 0zm2.235 6.335a.683.683 0 01.679-.682h4.073a.677.677 0 01.679.682.683.683 0 01-.68.681h-4.072a.677.677 0 01-.68-.681zm0-4.09a.683.683 0 01.679-.683h6.788a.678.678 0 01.68.682.683.683 0 01-.68.682h-6.788a.678.678 0 01-.68-.682zm0-4.091a.683.683 0 01.679-.682h9.504a.678.678 0 01.678.682.683.683 0 01-.678.681h-9.504a.677.677 0 01-.68-.681zm0 12.271a.683.683 0 01.679-.681h1.357a.677.677 0 01.68.681.683.683 0 01-.68.682h-1.357a.677.677 0 01-.68-.682z',
                    :fill='sortProp === sortPropertyValue.created ? "#3C5E8F" : "#91B4E7"'
                  )
            .table-cell.bg-white.top-0.sticky
              .flex.items-center
                span.text-annotationColor.text-sm.font-SourceSansPro.leading-10.font-semibold.mr-2 {{ $t("sessions.user") }}
                svg.transition-transform.ease-in-out.duration-500.cursor-pointer(
                  @click='sortChange(sortPropertyValue.user)',
                  width='24',
                  height='24',
                  fill='none',
                  xmlns='http://www.w3.org/2000/svg',
                  :style='sortProp === sortPropertyValue.user && sortOrder === "descending" && { transform: "scale(1,-1)" }'
                )
                  path.transition-colors.ease-in-out.duration-200(
                    d='M6.395 20a.677.677 0 00.68-.682V5.683a.683.683 0 00-.68-.682.677.677 0 00-.679.682v13.635a.683.683 0 00.68.682z',
                    :fill='sortProp === sortPropertyValue.user ? "#3C5E8F" : "#91B4E7"'
                  )
                  path.transition-colors.ease-in-out.duration-200(
                    d='M9.591 8.893a.682.682 0 000-.966L6.876 5.2a.679.679 0 00-.962 0L3.2 7.927a.683.683 0 00.74 1.114.68.68 0 00.221-.148l2.235-2.246L8.63 8.893a.679.679 0 00.961 0zm2.235 6.335a.683.683 0 01.679-.682h4.073a.677.677 0 01.679.682.683.683 0 01-.68.681h-4.072a.677.677 0 01-.68-.681zm0-4.09a.683.683 0 01.679-.683h6.788a.678.678 0 01.68.682.683.683 0 01-.68.682h-6.788a.678.678 0 01-.68-.682zm0-4.091a.683.683 0 01.679-.682h9.504a.678.678 0 01.678.682.683.683 0 01-.678.681h-9.504a.677.677 0 01-.68-.681zm0 12.271a.683.683 0 01.679-.681h1.357a.677.677 0 01.68.681.683.683 0 01-.68.682h-1.357a.677.677 0 01-.68-.682z',
                    :fill='sortProp === sortPropertyValue.user ? "#3C5E8F" : "#91B4E7"'
                  )
            .table-cell.bg-white.top-0.sticky
              .flex.items-center
                span.text-annotationColor.text-sm.font-SourceSansPro.leading-10.font-semibold.mr-2 {{ $t("sessions.event") }}
                svg.transition-transform.ease-in-out.duration-500.cursor-pointer(
                  @click='sortChange(sortPropertyValue.type)',
                  width='24',
                  height='24',
                  fill='none',
                  xmlns='http://www.w3.org/2000/svg',
                  :style='sortProp === sortPropertyValue.type && sortOrder === sortOrderValue.desc && { transform: "scale(1,-1)" }'
                )
                  path.transition-colors.ease-in-out.duration-200(
                    d='M6.395 20a.677.677 0 00.68-.682V5.683a.683.683 0 00-.68-.682.677.677 0 00-.679.682v13.635a.683.683 0 00.68.682z',
                    :fill='sortProp === sortPropertyValue.type ? "#3C5E8F" : "#91B4E7"'
                  )
                  path.transition-colors.ease-in-out.duration-200(
                    d='M9.591 8.893a.682.682 0 000-.966L6.876 5.2a.679.679 0 00-.962 0L3.2 7.927a.683.683 0 00.74 1.114.68.68 0 00.221-.148l2.235-2.246L8.63 8.893a.679.679 0 00.961 0zm2.235 6.335a.683.683 0 01.679-.682h4.073a.677.677 0 01.679.682.683.683 0 01-.68.681h-4.072a.677.677 0 01-.68-.681zm0-4.09a.683.683 0 01.679-.683h6.788a.678.678 0 01.68.682.683.683 0 01-.68.682h-6.788a.678.678 0 01-.68-.682zm0-4.091a.683.683 0 01.679-.682h9.504a.678.678 0 01.678.682.683.683 0 01-.678.681h-9.504a.677.677 0 01-.68-.681zm0 12.271a.683.683 0 01.679-.681h1.357a.677.677 0 01.68.681.683.683 0 01-.68.682h-1.357a.677.677 0 01-.68-.682z',
                    :fill='sortProp === sortPropertyValue.type ? "#3C5E8F" : "#91B4E7"'
                  )
            .table-cell.bg-white.top-0.sticky
              .flex.items-center
                span.text-annotationColor.text-sm.font-SourceSansPro.leading-10.font-semibold.mr-2 {{ $t("sessions.ip") }}
        .table-row-group
          .table-row.cursor-pointer.hover_bg-reallylightblue(
            v-for='(session, index) in filterData',
            :key='index'
          )
            .table-cell.truncate
              span.leading-10.text-sm.font-bold.transition-colors.ease-in-out.duration-200.text-darkblue {{ session.created }}
            .table-cell.truncate.sessions-table-columns__user
              span.leading-10.text-sm.font-bold.transition-colors.ease-in-out.duration-200.text-darkblue {{ session.user }}
            .table-cell.truncate
              span.leading-10.text-sm.font-bold.transition-colors.ease-in-out.duration-200.text-darkblue {{  session.type==='session_created' ? $t("users.enter") : $t("users.exit")  }}
            .table-cell.truncate
              span.leading-10.text-sm.font-bold.transition-colors.ease-in-out.duration-200.text-darkblue {{  session.ip_address  }}
</template>

<script>
import moment from 'moment'
import { mapActions, mapState } from 'vuex'
import {
  initSortValue,
  sortOrderValue,
  sortPropertyValue
} from './constants.js'

export default {
  components: {
    downloadIcon: () => import('@/assets/svg-icons/excel-export.svg')
  },
  data() {
    return {
      filterString: '',
      sessionsHref: '',
      sortProp: initSortValue.sortProp,
      sortOrder: initSortValue.sortOrder,
      activeTab: 'day',
      sortOrderValue,
      sortPropertyValue
    }
  },
  computed: {
    ...mapState('company', {
      sessions: (state) => state.sessions,
      sessionsDate: (state) => state.sessionsDate
    }),
    filterData() {
      const sessions = this.sessions.filter(
        (session) =>
          JSON.stringify(session)
            .toLowerCase()
            .indexOf(this.filterString.toLowerCase()) !== -1
      )
      return sessions.sort((a, b) => this.getFilterCondition(a, b))
    }
  },
  watch: {
    sessionsDate(val) {
      this.sessionsHref = `/api_v1/sessions_excel?body=${JSON.stringify({
        from: val
      })}`
    }
  },
  methods: {
    searching(val) {
      this.filterString = val
    },
    changeTab(time) {
      this.activeTab = time
      this.getSessions(time)
    },
    ...mapActions('company', {
      getSessions: 'GET_SESSIONS'
    }),
    downloadSessions(e) {
      if (!this.sessionsHref) {
        e.preventDefault()
      }
    },
    sortChange(prop) {
      if (this.sortProp === prop) {
        if (
          this.sortOrder === sortOrderValue.desc &&
          this.sortProp !== initSortValue.sortProp
        ) {
          this.sortOrder = sortOrderValue.asc
          this.sortProp = initSortValue.sortProp
          return
        }

        if (this.sortOrder === sortOrderValue.asc) {
          this.sortOrder = sortOrderValue.desc
          return
        }
      } else {
        this.sortProp = prop
      }

      this.sortOrder = sortOrderValue.asc
    },
    getFilterCondition(a, b) {
      const aValue = a[this.sortProp]
      const bValue = b[this.sortProp]

      if (this.sortOrder === sortOrderValue.asc) {
        if (aValue > bValue) return 1
        if (bValue > aValue) return -1
      }

      if (this.sortOrder === sortOrderValue.desc) {
        if (aValue > bValue) return -1
        if (bValue > aValue) return 1
      }

      return 0
    }
  },
  mounted() {
    const datetime = moment()
      .set({ hour: 0, minute: 0, second: 0 })
      .format('YYYY-MM-DD HH:mm:ss')
    this.sessionsHref = `/api_v1/sessions_excel?body=${JSON.stringify({
      from: datetime
    })}`
    this.getSessions()

    this.$refs.wrapper.focus()
    this.$refs.wrapper.style.outline = 'none'
  }
}
</script>

<style lang="stylus" scoped>
.session-container{
  width: 560px;
  margin-right: -50px;
}
.skif-load-link {
  display: inline-block;
  margin: 0 0 0 12px;
  color: #5477A9;
  opacity: 0.9;
  width: 20px;
  height: 20px;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
  }
  &.--disabled {
    opacity: 0.5;
    cursor: default;
  }
}
</style>
